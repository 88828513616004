<template>
  <span :class="['zspinner', formattedSize, formattedVariant]" />
</template>

<script setup lang="ts">
import type { SpinnerSize, SpinnerVariant } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  size?: SpinnerSize
  variant?: SpinnerVariant
}>(), {
  size: 'md',
  variant: 'primary',
})

const formattedSize = computed(() => {
  return `zspinner-${props.size}`
})

const formattedVariant = computed(() => {
  return `zspinner-${props.variant}`
})
</script>
